<template>
  <div class="pbf">
     
     <BrandTabGrowers
      :inurl="'/brands/' + props.id + '/growers'"  
      :brand-id="props.id"    
      />

  </div>
</template>


<script setup lang="ts">

interface Props {
  id: number
}

const props = defineProps<Props>()

</script>

<style scoped>
 

</style>
